import React, { useCallback, useEffect } from "react"
import { subscribe } from "@app/api/mobile/receive"
import useFetchInterceptor from "@app/api/useFetchInterceptor"
import { Logger } from "@app/model"
import { setAccessToken } from "@app/utils"
import * as Sentry from "@sentry/react"

import { CallProvider } from "./CallProvider"
import ErrorBoundaryFallback from "./ErrorBoundaryFallback"
import LiveSwitchCall from "./LiveSwitchCall"
import { WebSocketsProvider } from "./WebSocketsProvider"

const App = (): JSX.Element => {
  const [ready, setReady] = React.useState(false)
  useFetchInterceptor()

  const handleReceiveMessage = useCallback(
    (message: string): void => {
      if (message) {
        setAccessToken(message)
        setReady(true)
      } else {
        Logger.log("TOKEN_REFRESH")
      }
    },
    [setReady],
  )

  useEffect(() => {
    subscribe(handleReceiveMessage)
  }, [handleReceiveMessage])

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      {ready ? <AppView /> : <></>}
    </Sentry.ErrorBoundary>
  )
}

const AppView = (): JSX.Element => {
  return (
    <WebSocketsProvider>
      <CallProvider>
        <div className="grow h-full p-[1px]">
          <LiveSwitchCall />
        </div>
      </CallProvider>
    </WebSocketsProvider>
  )
}

export default App
